<template>
    <div>
        <div class="clock-container">
            <img src="../assets/icons/chronometer.svg" class="clock-icon">
            <div class="time">
                [ {{min}}:{{seg}} ]
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'ClockDown',
    props:['minuto','segundo'],
    data:()=>({
        seg:59,
        min:0
    }),
    methods:{
        segundos(){
            this.min = this.minuto;
            this.seg = this.segundo;
            if(this.seg>0){
                this.seg--;
            }
            if(this.seg==0&&this.min>=1){
                this.seg=59
                this.min--;
            }
            
            const timerUpdate = setInterval(()=>{
                
                if(this.min<=0&&this.seg<=0)
                {
                    clearInterval(timerUpdate);
                    this.$emit('setgameruning')
                }
                else
                    {
                        this.seg--;
                        if(this.seg<0)
                        {   
                            if(this.min!=0)
                            {
                                this.min--;
                                this.seg=59;
                            }  
                        } 
                    }
                
            },1000)
        },
    },
    mounted(){
        this.segundos()
        
    }
}
</script>
<style>
.clock-container{
    margin-left: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top:1em;
}
.time{
    color:white;
    font-size: 2.2em;
}
</style>
<template>
    <div class="results-game">
        <div class="rg__teacher-container">
            <img class="rg__lion-teach" src="../assets/characters/leon profe1.png" alt="">
        </div>
        <div>
            <div class="graph-score-container">
                <div class="graph-score">
                    <div class="graph-score___graph">
                        <div class="score">
                            {{scoreGame}} puntos
                        </div>
                        <div class="square">
                            <div class="progress-bar" id="progressbar"></div>
                        </div>
                        <div class="brain">
                            <img src="../assets/icons/brain.svg" alt="">
                        </div>
                    </div>
                    <div class="graph-score__description">
                        <div :class="['description__name',optima]">Óptima</div>
                        <div :class="['description__name',buena]">Buena</div>
                        <div :class="['description__name',suficiente]">Suficiente</div>
                        <div :class="['description__name',escasa]">Escasa</div>
                        <div :class="['description__name',insuficiente]">Insuficiente</div>
                    </div>
                </div>
            </div>
            <button class="success-btn" @click="$router.replace('/juegos')">OK</button>
        </div>
    </div>
</template>
<script>

import {mapState} from 'vuex'
import {auth,db} from '../firebase'

export default {
    name:'ResultsGame',
    props:['nombrejuego','nivel','inteligencia'],
    data:()=>({
        optima:'',
        buena:'',
        suficiente:'',
        escasa:'',
        insuficiente:'',

    }),
    computed:{
        ...mapState(['scoreGame','scoreDescription'])
    },
    methods:{
        pintarResultado(){
            if(this.scoreGame==0){
                this.insuficiente = 'description__success'
            }
            if(this.scoreDescription=='optima')
            {
                this.optima = 'description__success'
            }
            else if(this.scoreDescription=='buena')
            {
                this.buena = 'description__success'
            }
            else if(this.scoreDescription=='suficiente')
            {
                this.suficiente = 'description__success'
            }
            else if(this.scoreDescription=='escasa')
            {
                this.escasa = 'description__success'
            }
            if(this.scoreDescription=='insuficiente')
            {
                this.insuficiente = 'description__success'
            }
        },
        activarAnimacion(){

            let pb = document.getElementById('progressbar')
            pb.style.height = '0%'
            let total = this.scoreGame/10
            if(total>100){
                total = 100
            }
            for(let i = 0;i<=total;i++)
            {
                window.setTimeout(()=>{
                    pb.style.height = i+'%'
                },100)
                
            }
            
        },
        getCurrentUser(){
            return new Promise((resolve,reject)=>{
                const unsubscribe = auth.onAuthStateChanged(
                user =>{
                    unsubscribe();
                    resolve(user);
                },
                ()=>{
                    reject();
                }
                );
            });
        },
        async guardarPuntos(){
            let user = await this.getCurrentUser()
            db.collection("usuarios").doc(user.uid).collection("historial").add({
                fecha: new Date(),
                nivel:this.nivel,
                tipo:this.inteligencia,
                juego:this.nombrejuego,
                score:this.scoreGame
            })
            .then(() => {
                //console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                alert("Ocurrió un error inesperado, su puntaje no se guardará :c")
                console.error("Error adding document: ", error);
            });
        }
    },
    async mounted(){
        let a = new Audio(require('../assets/juegos/audios/puntaje.mp3'))
        a.play()
        await this.activarAnimacion()
        await this.pintarResultado()
        await this.guardarPuntos()
        
    }
}
</script>
<style>
.results-game{
    text-align: center;
}
.graph-score-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top:1em;
}
.graph-score{
    display: flex;
    justify-content: center;
}
.graph-score__graph{
    display: flex;
    flex-direction: column;
    align-content: center;
}
.score{
    color:#C9BFBF;
    font-size: 2em;
}
.square{
    width: 7em;
    height: 45vh;
    background-color:#917C9E;
    border:#C8982B 3px solid;
    position: relative;
}
.progress-bar{
    width: 100%;
    height: 0%;
    bottom: 0px;
    background-color: #d2f145;
    position: absolute;
    transition: all 3s;
}
.brain{
    display: flex;
    justify-content: center;
    transform: translateY(-50%);
}
.graph-score__description{
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 10px;
    transform: translateY(-5%);
}
.description__name{
    font-size: 2em;
    color:#5D417F;
}
.description__success{
    color:#EFD598;
    font-weight: bold;
}
.success-btn{
    width: 90%;
    font-size:1.1em;
    font-weight: bold;
    color:white;
    background-color: #1DAF30;
    border:0px;
    border-radius: 20px;
    padding-top: .3em;
    padding-bottom: .3em;
    cursor: pointer;
}
.rg__lion-teach{
    display: none;
}
@media (min-width: 900px) and (orientation: landscape) {
    .results-game{
        background-image: url('../assets/bg-desktop/bg-home.svg');
        background-position-x: -20px;
        background-position-y: -400px;
        background-size: 100%;
        background-repeat: no-repeat;
        margin-top: 2em;
        display: grid;
        grid-template-columns: 40% 40%;
        justify-items: center;
        align-content: center;
        align-items: center;
        justify-content: center;
    }
    .rg__teacher-container{
        width: 70%;
        height: 70vh;
    }
    .rg__lion-teach{
        width: 100%;
        height: 100%;
        display: block;
    }
}
</style>
<template>
    <div class="tutorial-game-container">
        <div class="teacher-speaking">
            <div class="teacher-speaking__box">
                <span>{{text}}</span>    
            </div>
            <div class="lion-img">
            </div>
            <div class="tutorial-box">
                <video :src="video" loop autoplay id="videotutorial"></video>
            </div>
        </div>
        <div class="buttons-container">
            <button class="btn btn-start" @click="goGame()">Iniciar</button>
            <button class="btn btn-cancel" @click="goBack()">Regresar</button>
        </div>
    </div>
</template>
<script>
import {mapMutations} from 'vuex'
export default {
    name:'TutorialGame',
    props:['text','audio','video'],
    methods:{
        ...mapMutations(['changeTutorialStatus']),
        goGame(){
            let video = document.getElementById('videotutorial');
            video.pause();
            this.changeTutorialStatus(false)
        },
        goBack(){
            let video = document.getElementById('videotutorial');
            video.pause();
            this.$router.replace('/juegos')
        },
    },
    mounted(){
       /* try{
            let a = new Audio(require('../assets/juegos/audios/'+this.audio+'.mp3'))
            a.play()
        }catch(e){
            console.log(e)
        }*/
        
    }
}
</script>
<style>
.tutorial-game-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.teacher-speaking{
    width: 100%;
    display: grid;
    grid-template-columns: 45% 55%;
    grid-template-rows: 40vh 20vh;
    align-items: center;
    justify-items: center;
    justify-content: center;
    object-fit: cover;
    position: relative;
}
.teacher-speaking img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(20%,20%);
}
.lion-img{
    width: 100%;
    height: 100%;
    background-image: url('../assets/characters/leon-instruciones.png');
    background-size: 100% 100%;
    background-position-x: 30px;
    transform: translateY(5%);
    grid-column: 2;
}
.teacher-speaking__box{
    background-color: #EEEFE4;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7em;
    width: 50%;
    padding: 0.5em;
    border-radius: 15px;
    left: 5%;
    top: 10%;
    position: absolute;

}
.teacher-speaking span{
    text-align: center;
    font-size: 1.3em;
}
.tutorial-box{
    width: 100%;
    height: 10em;
    background-color:#EEEFE4;
    grid-column: 1 / 3;
    grid-row: 2 / 2;
    border-radius: 10px;
    transform: translateY(1%);
}
.tutorial-box video{
    width: 100%;
    height: 100%;
}
.buttons-container{
    width: 100%;
    margin-top: 1.3em;
    display: flex;
    justify-content: space-evenly;
}
.btn{
    font-size: 1.3em;
    padding:0.3em 1em;
    border-radius:20px;
    border: 0px;
    width: 7em;
    color:white;
    cursor: pointer;
}
.btn-start{
    background-color: #1DAF30;
}
.btn-cancel{
    background-color: #AF1D2C;
}
input:focus, input.form-control:focus,button {

    outline:none !important;
    outline-width: 0 !important;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}
@media (min-width: 900px) and (orientation: landscape) {
    .teacher-speaking{
        width: 40%;
    }
    .buttons-container{
        justify-content: center;
    }
    .btn{
        margin:1em;
    }
}
</style>